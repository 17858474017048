import React, { useEffect, useState } from 'react'
import { View, StyleSheet, Dimensions } from 'react-native'
import { getFullPost, setPostFavorite } from '../../../services/api/Posts'
import Paths from '../../../constants/Paths'
import { CustomText } from '../../text/StyledText'
import { ButtonPrimary } from '../../buttons/StyledButtons'
import { txt } from '../../../locales/i18n'
import NormalIcon from '../../icons/NormalIcon'
import { getConversationId } from '../../../services/api/Inbox'
import FavoriteIndicator from '../../headers/FavoriteIndicator'
import { ImageHighlightPost } from '../../images/ImageHighlight'
import PageTitle from '../../tags/PageTitle'
import { formatForMetaTitle } from '../../text/TextFormatter'
import { SpacingStyles } from '../../../styles'
import store from '../../../redux/store'
import PostNotFound from './PostNotFound'
import ContactUnavailableDialog from '../../dialogs/ContactUnavailableDialog'
import SignUpDialog from './Dialogs/SignUpDialog'
import { OwnPostDialog } from './Dialogs/OwnPostDialog'
import { useNavigation, useRoute } from '@react-navigation/core'
import { setOrigin } from '../../../redux/actions'
import Card from '../../Card'
import { useSelector } from 'react-redux'
import ReportContent from '../../callToActions/reportContent'
import { getHasFeatureOne, getSubscription } from "../../../services/api/User"
import CustomPressable from "../../pressables/CustomPressable"

import { useAtomValue } from 'jotai'
import { SeClickedPostIdsAtom, DkClickedPostIdsAtom } from '../../../atoms'
import { getUniverseConfigById } from "../../../universe/universeController"

const small = Dimensions.get('window').width < 500

export default function Post(props) {
  const navigation = useNavigation()
  const [loading, setLoading] = useState(true)
  const [item, setItem] = useState({
    id: 0,
    is_favorite: 0,
    title: '',
    body: '',
    username: '',
    user_id: null,
    img_filename: '',
    location: '',
    municipality_id: 0,
    own_municipality_id: 0,
    own_municipality_name: '',
    municipality_contact: '',
    subscriber: false,
  })
  const [accessDialogVisible, setAccessDialogVisible] = useState(false)
  const [ownPostVisible, setOwnPostVisible] = useState(false)
  const [showNotSignedInDialog, setShowNotSignedInDialog] = useState(false)
  const [notFound, setNotFound] = useState(false)
  const [subscription, setSubscription] = useState(null)
  const [municipalityName, setMunicipalityName] = useState(null)
  const [postOwnerHasFeatureOne, setPostOwnerHasFeatureOne] = useState(false)
  const [universe, setUniverse] = useState(null)

  const sePostFeedIds = useAtomValue(SeClickedPostIdsAtom)
  const dkPostFeedIds = useAtomValue(DkClickedPostIdsAtom)
  const ownUserId = useSelector((state) => state.user?.id)
  const country = useSelector((state) => state.user?.country)

  const ownPost = item.user_id === ownUserId
  const route = useRoute()
  const postId = route.params.id ?? null

	useEffect(() => {
		setLoading(true)

		if (postId === null) {
			setNotFound(true)
			return setLoading(false)
		}

		getFullPost(postId).then((post) => {
			if (!post) {
				setNotFound(true)
				return setLoading(false)
			}

			post.username = setUsername(post)
			setItem(post)
			setMunicipalityName(post.municipality_name)

			if (post?.universe_id) {
				const universeConfig = getUniverseConfigById(post?.universe_id)
				setUniverse(universeConfig)
			}

			if (post.user_id !== ownUserId) {
				const headerRight = () => <ReportContent reportingTypeId={0} contentId={postId} />
				navigation.setOptions({ headerRight })
			}

			if (ownUserId) {
				getSubscription().then((sub) => {
					const notPending = sub?.state === 'active' && sub?.pending_invoices === 0
					const notDunning = sub?.state === 'active' && sub?.dunning_invoices === 0
					if (sub?.state === 'active' && notPending && notDunning) setSubscription(sub)
				})
			}

			// Using below to check that post owners with feature one can receive messages as well.
			// If we grant feature 1 to specific users without a sponsor (example: K7)
			getHasFeatureOne(post?.user_id).then(setPostOwnerHasFeatureOne)
			setLoading(false)
		})
	}, [postId])

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      getFullPost(postId).then((post) => {
        if (post) {
          post.username = setUsername(post)
          setItem(post)
        }
        else setNotFound(true)
      })
    })

    return unsubscribe
  }, [navigation, postId])

  const setUsername = (post) => {
    const username = post?.username ?? ''

    const own = post.user_id === ownUserId
    if (own) return username

    const hasFeature1 = store.getState().user?.features?.includes(1)
    if (hasFeature1) return username

    const [firstName, ...lastNames] = username.split(' ')
    if (lastNames.length === 0) return username

    return firstName
  }

  const showAccessDialog = () => setAccessDialogVisible(true)
  const hideAccessDialog = () => setAccessDialogVisible(false)

  const handleSend = async () => {
    if (ownUserId === undefined) {
      toggleNotSignedInDialog()
      setPostAsOrigin()
      return
    }
    if (!item.user_id) return

    if (!canSendMessage()) {
      showAccessDialog()
    } else {
      const conversationId = await getConversationId({ postId: item.id, receiverId: item.user_id })
      if (conversationId) {
        props.navigateToConversation(conversationId)
      } else {
        props.navigateToNewConversation(item)
      }
    }
  }

  const toggleFavorite = async () => {
    await setPostFavorite(item.id, item.is_favorite)
    setItem((prevState => ({ ...prevState, is_favorite: item.is_favorite ? 0 : 1 })))
  }

  const toggleOwnPostDialog = () => {
    setOwnPostVisible((prevState => !prevState))
  }

  const toggleNotSignedInDialog = () => {
    setShowNotSignedInDialog((prevState => (!prevState)))
  }

	const canSendMessage = () => {
		// German users can send messages to everyone
		if (country === 'Germany') return true

		if (item.universe_id === 10 || item.universe_id === 14) return true // Always allow to write to Nytårsvenner DK and SE

		const hasMessageFeature = store.getState().user?.features?.includes(1)
		if (hasMessageFeature) return true

		if (country === 'Sweden') {
			const postIndex = sePostFeedIds.indexOf(Number(props.id))
			
			// incremental steps for display of subscription dialog
			const contactIncrement = 2
			return (postIndex + 1) % contactIncrement !== 0
		}

		if (country === 'Denmark') {
			const postIndex = dkPostFeedIds.indexOf(Number(props.id))
			
			// incremental steps for display of subscription dialog
			const contactIncrement = 3
			return (postIndex + 1) % contactIncrement === 1
		}

		const sameMunicipality = item.own_municipality_id === item.municipality_id
		const isUniversePostInUniverse = !!item.universe_id && !!route.params.universe
		return hasMessageFeature || item.subscriber || !sameMunicipality || isUniversePostInUniverse || postOwnerHasFeatureOne
	}

  const editPost = () => {
    props.navigateToEditPost(item.id)
  }

  const setPostAsOrigin = () => {
    store.dispatch(
      setOrigin({
        screen: 'Post',
        stack: 'HomeStack',
        rootStack: 'app',
        params: { id: item.id },
      }),
    )
  }

  if (!loading) {
    if (notFound) {
      return (
        <PostNotFound
          navigateToBobls={props.navigateToBobls}
          navigateToFrontpage={props.navigateToFrontpage}
          signedIn={!!ownUserId}
        />
      )
    }

    return (
      <View style={styles.container}>
        <PageTitle title={formatForMetaTitle(item.title)} />

        <Card largeRadius style={styles.contentContainer}>
          <View style={[styles.imageContainer, { minHeight: small ? 200 : 225 /* minHeight to fix browser bug */ }]}>
            <ImageHighlightPost
              size={small ? 'medium' : 'large'}
              source={{
                uri: Paths.imageUrlBase + item.img_filename,
              }}
              borderColor={universe ? universe?.boblHighlightColor ?? null : null}
            />
          </View>

          <View style={styles.actionContainer}>
            {ownPost ? (
              <CustomPressable
                onPress={editPost}
                accessibilityRole="button"
                accessibilityLabel={txt('post.editTitle')}
              >
                <NormalIcon name="edit" size={24} />
              </CustomPressable>
            ) : ownUserId !== undefined ? (
              <FavoriteIndicator
                size={small ? 28 : 36}
                toggleFavorite={toggleFavorite}
                isFavorite={item.is_favorite}
              />
            ) : null}
          </View>

          <View style={styles.infoContainer}>
            <View
              accessible
              accessibilityRole="text"
              accessibilityLabel={
                txt('fullPost.usernameLabel') + item.username
              }
              accessibilityHint={''}
              style={styles.infoItem}
            >
              <NormalIcon
                accessibilityLabel={txt('fullPost.usernameIcon')}
                name="user"
                size={18}
                style={styles.icon}
              />
              <CustomText font="smallBold">
                {item.username}
              </CustomText>
            </View>

            {!!item.location && (
              <View
                accessible
                accessibilityRole="text"
                accessibilityLabel={
                  txt('fullPost.locationLabel') + item.location
                }
                style={styles.infoItem}
              >
                <NormalIcon
                  accessibilityLabel={txt('fullPost.locationIcon')}
                  name="map-marker-alt"
                  size={18}
                  style={styles.icon}
                />
                <CustomText font="smallBold">
                  {item.location}
                </CustomText>
              </View>
            )}

            {subscription && municipalityName && (
              <View
                accessible
                accessibilityRole="text"
                accessibilityLabel={txt('fullPost.locationLabel') + item.location}
                style={styles.infoItem}
              >
                <NormalIcon
                  accessibilityLabel={txt('fullPost.locationIcon')}
                  name="map"
                  size={18}
                  style={styles.icon}
                />
                <CustomText font="smallBold">
                  {municipalityName + txt('fullPost.municipality')}
                </CustomText>
              </View>
            )}
          </View>

          <CustomText
            accessible
            accessibilityRole="header"
            font="largeBold"
            style={styles.title}
          >
            {item.title}
          </CustomText>

          {item.body.split('\n\n').map((paragraph, index) => (
            <CustomText key={index} accessible style={styles.body}>
              {paragraph}
            </CustomText>
          ))}

          <ButtonPrimary
            style={styles.messageButton}
            title={txt('fullPost.sendMessageBtnText')}
            onPress={ownPost ? toggleOwnPostDialog : handleSend}
            icon="comment-lines"
            stroke={'far'}
          />
        </Card>

        <ContactUnavailableDialog
          visible={accessDialogVisible}
          hide={hideAccessDialog}
          post={item}
          username={item.username}
        />
        <SignUpDialog
          navigateToFrontpage={props.navigateToFrontpage}
          visible={showNotSignedInDialog}
          toggle={toggleNotSignedInDialog}
          username={item.username}
        />
        <OwnPostDialog
          visible={ownPostVisible}
          toggle={toggleOwnPostDialog}
        />
      </View>
      )
    }
    return <View />
  }

const styles = StyleSheet.create({
  container: { flex: 1, ...SpacingStyles.widthAndHeight, padding: 12 },
  contentContainer: {
    padding: small ? 18 : 36,
    paddingTop: 0,
    margin: 6,
    alignSelf: 'center',
    marginTop: small ? 100 : 112,
    marginBottom: 20,
    width: '100%',
    maxWidth: 600,
  },
  infoContainer: {
    marginTop: 12,
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  infoItem: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 6,
  },
  title: {
    marginTop: 24,
  },
  body: {
    marginTop: 6,
  },
  actionContainer: {
    position: 'absolute',
    right: small ? 18 : 24,
    top: small ? 18 : 24,
  },
  icon: { marginRight: 4 },
  imageContainer: {
    flex: 1,
    flexDirection: 'row',
    marginTop: small ? -100 : -112,
  },
  messageButton: {
    marginTop: small ? 24 : 36,
    marginBottom: 8,
  },
})
