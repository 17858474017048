import { atom, createStore } from 'jotai'
import { atomWithStorage, createJSONStorage } from 'jotai/utils'
import AsyncStorage from '@react-native-async-storage/async-storage'

export const jotaiStore = createStore()

const storage = createJSONStorage(() => AsyncStorage)
export const SeClickedPostIdsAtom = atomWithStorage('seClickedPostIds', [], storage)
export const DkClickedPostIdsAtom = atomWithStorage('DkClickedPostIds', [], storage)
export const showSubscriptionDialogAtom = atom(false)
export const showTooManyActivePostsDialogAtom = atom(false)
export const deepLinkingStatusAtom = atom('idle')
export const forceReloadAtom = atom(Date.now())
