import React, { useEffect, useState } from 'react'
import CustomDialog from "../../../dialogs/CustomDialog"
import { CustomText } from "../../../text/StyledText"
import { Linking, Pressable, ScrollView, StyleSheet, View } from "react-native"
import CustomPressable from "../../../pressables/CustomPressable"
import Colors from "../../../../constants/Colors"
import NormalIcon from "../../../icons/NormalIcon"
import { txt } from "../../../../locales/i18n"
import { ButtonSecondary } from "../../../buttons/StyledButtons"
import { addToWaitlist, getBookedSessions, getWaitlistUser, waitlistAccess } from "../../../../services/api/snakSammen"
import { useSelector } from "react-redux"

export default function GetStartedDialog({ visible, close }) {
	const user = useSelector((state) => state.user)

	const [showWaitlist, setShowWaitlist] = useState(false)
	const [errorMessage, setErrorMessage] = useState(null)
	const [successMessage, setSuccessMessage] = useState(null)


	useEffect(() => {
		handleShowWaitlist()
	}, [])

	async function handleShowWaitlist() {
		const hasWaitlistAccess = await waitlistAccess()
		if (!hasWaitlistAccess) return setShowWaitlist(false)

		const alreadySignedUp = await getWaitlistUser(user.id)
		if (alreadySignedUp) return setShowWaitlist(false)

		const bookings = await getBookedSessions()
		if (bookings.length > 0) return setShowWaitlist(false)
		
		setShowWaitlist(true)
	}

	async function handleWaitlistSignup() {
		const added = await addToWaitlist()
		if (!added) return setErrorMessage(txt('videoChat.user.getStartedDialog.waitlistError'))

		setSuccessMessage(txt('videoChat.user.getStartedDialog.waitlistSuccess'))
		setTimeout(() => {
			close()
			setSuccessMessage(null)
			setShowWaitlist(false)
		}, 2000)
	}

	return (
		<CustomDialog visible={visible} onDismiss={close} style={styles.dialog}>

			<CustomPressable onPress={close} style={styles.closeIcon}>
				<NormalIcon name="times" size={20} color={Colors.darkGrey}/>
			</CustomPressable>

			<ScrollView style={{ flexGrow: 0, margin: 12 }}>

				{/* Using a pressable as a wrapper for all content to make the dialog scrollable */}
				<Pressable accessibilityRole="none" style={{ paddingHorizontal: 12, paddingVertical: 24 }}>

					<CustomText font="gotham-bold-large" style={{ marginBottom: 18 }}>{txt('videoChat.user.getStartedDialog.title')}</CustomText>
					<CustomText font="gotham-bold" style={{ marginBottom: 6 }}>{txt('videoChat.user.getStartedDialog.subTitle1')}</CustomText>
					<CustomText font="gotham-small">{txt('videoChat.user.getStartedDialog.body1')}</CustomText>
					<View style={styles.contentAlignment}>
						<CustomText font="gotham-small">{txt('videoChat.user.getStartedDialog.body2')}</CustomText>
						<CustomPressable onPress={() => Linking.openURL('mailto:support@snaksammen.dk')}>
							<CustomText font="gotham-bold-small">{'support@snaksammen.dk'}</CustomText>
						</CustomPressable>
					</View>

					<CustomText font="gotham-bold" style={{ marginTop: 18, marginBottom: 6 }}>{txt('videoChat.user.getStartedDialog.subTitle2')}</CustomText>
					<View style={styles.contentAlignment}>

						<CustomText font="gotham-small">{txt('videoChat.user.getStartedDialog.body3')}</CustomText>
						<CustomPressable onPress={() => Linking.openURL('mailto:snaksammen@rodekors.dk')}>
							<CustomText font="gotham-bold-small">{'snaksammen@rodekors.dk'}</CustomText>
						</CustomPressable>

						<CustomText font="gotham-small">{txt('videoChat.user.getStartedDialog.body4')}</CustomText>
						<CustomPressable onPress={() => Linking.openURL('tel:29350521')}>
							<CustomText font="gotham-bold-small">{'29 35 05 21'}</CustomText>
						</CustomPressable>
					</View>

					<CustomText font="gotham-bold" style={{ marginTop: 18, marginBottom: 6 }}>{txt('videoChat.user.getStartedDialog.subTitle5')}</CustomText>
					<CustomPressable onPress={() => Linking.openURL('https://boblberg.dk/snaksammen/rammer-for-brug-af-snaksammen')}>
						<CustomText font="gotham-small" style={{ textDecorationLine: 'underline' }}>{txt('videoChat.user.getStartedDialog.guidelinesButton')}</CustomText>
					</CustomPressable>

					{showWaitlist && (
						<View>
							<CustomText font="gotham-bold" style={{ marginTop: 18, marginBottom: 6 }}>
								{txt('videoChat.user.getStartedDialog.subTitle3')}
							</CustomText>
							<CustomText font="gotham-small">{txt('videoChat.user.getStartedDialog.body5')}</CustomText>

							{successMessage ? (
								<View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: 24}}>
									<CustomText font="gotham-bold-small" style={{ color: Colors.green, alignSelf: 'center', paddingRight: 6 }}>{successMessage}</CustomText>
									<NormalIcon name="check" size={14} color={Colors.green} stroke="fas" />
								</View>
							) : (
								<View>
									<ButtonSecondary
										title={txt('videoChat.user.getStartedDialog.button')}
										onPress={handleWaitlistSignup}
										style={styles.button}
										titleStyle={styles.buttonTitle}
									/>
									{errorMessage && (
										<CustomText font="gotham-bold-small" style={{ color: Colors.red, alignSelf: 'center', marginTop: 24 }}>{errorMessage}</CustomText>
									)}
								</View>
							)}
						</View>
					)}

				</Pressable>
			</ScrollView>
		</CustomDialog>
	)
}

const styles = StyleSheet.create({
	dialog: {
		maxWidth: 500
	},
	closeIcon: {
		backgroundColor: Colors.greyLightLight,
		borderRadius: 12,
		padding: 8,
		position: 'absolute',
		top: 12,
		right: 12,
		zIndex: 10
	},
	contentAlignment: {
		flexDirection: 'row',
		alignItems: 'center',
		flexWrap: 'wrap'
	},
	button: {
		borderRadius: 3,
		marginTop: 24,
		borderColor: Colors.grey,
		borderWidth: 1,
	},
	buttonTitle: {
		color: Colors.grey,
		padding: 6,
		fontSize: 14,
		fontFamily: 'gotham-book',
	}
})