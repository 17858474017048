// libraries
import React, { useEffect, useRef, useState } from 'react'
import { Keyboard, Platform, StyleSheet, View, TouchableOpacity, Pressable } from 'react-native'
import { useSelector } from 'react-redux'
import { useRoute } from '@react-navigation/core'
import KeyboardSpacer from 'react-native-keyboard-spacer'

//api
import { UploadImage } from '../../services/api/UploadImage'
import { updateUser } from '../../services/api/User'
import * as AddressService from '../../services/api/Addresses'

// components
import { CustomText } from '../../components/text/StyledText'
import { InputPrimary } from '../../components/text/StyledTextinputs'
import { ButtonPrimary } from '../../components/buttons/StyledButtons'
import { CheckBox } from '../../components/formElements/formElements'
import { CustomSwitch } from '../../components/switches/StyledSwitches'
import NumberPicker from '../../components/formElements/NumberPicker'
import NormalIcon from '../../components/icons/NormalIcon'
import ImagePicker from '../../components/ImagePicker/ImagePicker'
import CustomDialog, { ContentContainer, ActionButton } from '../../components/dialogs/CustomDialog'
import MatchDialog from './MatchDialog'
import { PhoneNumberInput } from '../../components/formElements/PhoneNumberInput'

import store from '../../redux/store'
import { setChristmasDialogVisible } from '../../redux/actions'
import { txt } from '../../locales/i18n'
import Layout from '../../constants/Layout'
import Colors from '../../constants/Colors'
import Paths from '../../constants/Paths'
import PostalCodes from '../../constants/PostalCodes'
import { NoImageDialog } from "./noImageDialog"

export default function ChristmasPostEdit({ post, onSubmit, onDelete }) {
  const route = useRoute()
  const user = useSelector(state => state.user)
  const ownMunicipalityId = useSelector((state) => state.user?.municipality_id)
  const type = route.params?.type
  const [errorMessages, setErrorMessages] = useState({})
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const oldRkMatch = post?.rkMatch
  const [phoneNumber, setPhoneNumber] = useState(`${user.phone ?? ''}`)
  const [callCode, setCallCode] = useState(user.phone_prefix)
  const [submitError, setSubmitError] = useState(false)
  const [success, setSuccess] = useState(false)
  const phoneInputRef = useRef(null)

  const [postState, setPostState] = useState({
    type,
    imgId: null,
    imgFilename: null,
    title: '',
    body: '',
    municipalityId: null,
    postalCode: null,
    city: '',
    numAdults: 1,
    numChildren: 0,
    adultSpaces: 1,
    childSpaces: 0,
    rkMatch: false,
    rkContact: false,
    rkEngagement: false,
    status: 1,
  })
  const [infoMatchVisible, setInfoMatchVisible] = useState(false)
  const [infoMatchMyselfVisible, setInfoMatchMyselfVisible] = useState(false)
  const [image, setImage] = useState(null)
  const [noImageDialog, setNoImageDialog] = useState(false)
  const [imageInfo, setImageInfo] = useState(false)
  const [editing, setEditing] = useState(false)
  const allowRkMatch = Date.parse(new Date()) <= Date.parse(new Date(2024, 11, 19, 12))

  useEffect(() => {
    if (post) {
      setEditing(true)
      updatePostState(post)
    }
  }, [post])

  function updatePostState(post) {
    setPostState((state) => ({ ...state, ...post }))
  }

  function deleteImage() {
    setImage(null)
    updatePostState({ imgId: null, imgFilename: null })
  }

  function toggleRkMatch() {
    setPostState((prevState) => ({ ...prevState, rkMatch: !prevState.rkMatch }))
  }

  function toggleRkContact() {
    setPostState((prevState) => ({
      ...prevState,
      rkContact: !prevState.rkContact,
    }))
  }

  function toggleHidePost() {
    setPostState((prevState) => ({
      ...prevState,
      status: prevState.status !== 1 ? 1 : -1,
    }))
  }

  const preparePost = async () => {
    const post = postState
    if (image) post.imgId = await UploadImage(image, 0, 'Christmas')
    post.municipalityId = PostalCodes[post.postalCode] || ownMunicipalityId
    post.type = type
    post.title = post.title.trim()
    post.body = post.body.trim()
    return post
  }

  const handleEditAgain = () => {
    setSubmitting(false)
    setNoImageDialog(false)
  }

  const handleSubmit = async (post) => {
    await updateUser({phone: phoneNumber, phone_prefix: callCode})
    await onSubmit(post)
    if (oldRkMatch !== post.rkMatch && !post.rkMatch) {
      store.dispatch(setChristmasDialogVisible(true))
    }
    setSuccess(true)
    setTimeout(() => setSuccess(false), 1500)
  }

  const submitPostWithoutImage = async () => {
    const post = await preparePost()
    const valid = validateInput(post)
    if (valid) await handleSubmit(post)
    else {
      setSubmitError(true)
      setTimeout(() => setSubmitError(false), 1500)
    }
    setNoImageDialog(false)
    setSubmitting(false)
  }

  async function prepareSubmit() {
    Keyboard.dismiss()
    setSubmitting(true)
    const post = await preparePost()
    const valid = validateInput(post)
    if (valid) {
      if (!image && !post.rkMatch && (!postState?.imgFilename || !postState?.imgId)) {
        return setNoImageDialog(true)
      }
      await handleSubmit(post)
    } else {
      setSubmitError(true)
      setTimeout(() => setSubmitError(false), 1500)
    }
    setSubmitting(false)
  }

  function keyboardSpacerShow() {
    if (Platform.OS === 'ios') {
      return <KeyboardSpacer />
    }
  }

  const title = post
    ? txt('christmasFriends.createEditPost.edit')
    : type === 'guest'
    ? txt('christmasFriends.createEditPost.titleGuest')
    : txt('christmasFriends.createEditPost.titleHost')

  async function handlePostalCodeChange(postalCode) {
    postalCode = postalCode.replace(/[^0-9]/g, '')
    if (postalCode.length === 4) {
      const city = await AddressService.getCityFromPostalCode(postalCode)
      if (city !== undefined) updatePostState({ city })
    }

    updatePostState({ postalCode })
  }

  function handleNumAdultsChange(numAdults) {
    if (numAdults < 1) return
    updatePostState({ numAdults })
  }

  function handleAdultSpacesChange(adultSpaces) {
    if (adultSpaces < 1) return
    updatePostState({ adultSpaces })
  }

  function validateInput(post) {
    const errors = {}
    if (post.title === '') errors.title = true
    if (post.body === '') errors.body = true
    if (post.city === '') errors.city = true
    if (post.municipalityId === false) errors.postalCodeDontExist = true
    if (!post.postalCode) errors.postalCode = true
    if (post.rkMatch && !post.rkContact) errors.rkContact = true
    if (post.rkContact) {
      if (!phoneNumber) errors.phone = true
    }
    if (!phoneInputRef.current.isValid()) {
      errors.phoneInvalid = true
    }

    setErrorMessages(errors)
    return Object.keys(errors).length === 0
  }

  function error(type) {
    return (
      <CustomText style={styles.errorText} font={'small'}>
        {txt('christmasFriends.createEditPost.errors.' + type)}
      </CustomText>
    )
  }

  function DeleteDialog() {
    return (
      <CustomDialog visible={showDeleteDialog}>
        <ContentContainer>
          <CustomText>
            {txt('christmasFriends.createEditPost.deleteText')}
          </CustomText>
        </ContentContainer>
        <ActionButton onPress={() => onDelete(postState.id)}>
          {txt('post.deletePostYes')}
        </ActionButton>
        <ActionButton onPress={() => setShowDeleteDialog(false)}>
          {txt('post.deletePostNo')}
        </ActionButton>
      </CustomDialog>
    )
  }

  return (
    <View style={styles.container}>
      <CustomText style={styles.headerText}>{title}</CustomText>

      <View style={styles.imagePickerContainer}>
        <Pressable accessibilityRole="button" onPress={() => setImageInfo(true)} style={{width: 175, alignItems: 'center'}}>
          <NormalIcon name="info-circle" size={16} style={{alignSelf: 'flex-end', alignItems: 'flex-end'}}/>
        </Pressable>
        <ImagePicker
          editMode
          setImage={setImage}
          defaultImage={postState?.imgFilename ? Paths.imageUrlBaseJV + postState.imgFilename : null}
          onDelete={deleteImage}
        //   placeholder={ChristmasImage}
        />
      </View>

      <CustomText font={'smallBold'} style={styles.marginTop}>
        {txt('christmasFriends.createEditPost.title')}
      </CustomText>
      <InputPrimary
        accessibilityLabel={txt(
          'christmasFriends.createEditPost.titlePlaceHolder'
        )}
        accessibilityHint={txt('christmasFriends.createEditPost.titleHint')}
        placeholder={txt('christmasFriends.createEditPost.titlePlaceHolder')}
        value={postState.title}
        onChangeText={(text) => updatePostState({ title: text })}
      />
      {errorMessages.title && error('title')}

      <CustomText font={'smallBold'} style={styles.marginTop}>
        {txt('christmasFriends.createEditPost.description')}
      </CustomText>
      <InputPrimary
        accessibilityLabel={
          type === 'host'
            ? txt('christmasFriends.createEditPost.descriptionPlaceHolderHost')
            : txt('christmasFriends.createEditPost.descriptionPlaceHolderGuest')
        }
        accessibilityHint={txt('christmasFriends.createEditPost.descriptionHint')}
        placeholder={
          type === 'host'
            ? txt('christmasFriends.createEditPost.descriptionPlaceHolderHost')
            : txt('christmasFriends.createEditPost.descriptionPlaceHolderGuest')
        }
        multiline
        value={postState.body}
        onChangeText={(text) => updatePostState({ body: text })}
      />
      {errorMessages.body && error('body')}

      <CustomText font={'smallBold'} style={styles.marginTop}>
        {txt('christmasFriends.createEditPost.phone')}
      </CustomText>
      <PhoneNumberInput
        ref={phoneInputRef}
        style={{marginTop: 6}}
        accessibilityLabel={txt('christmasFriends.createEditPost.phone')}
        accessibilityHint={txt('christmasFriends.createEditPost.phoneHint')}
        placeholder={txt('christmasFriends.createEditPost.phonePlaceHolder')}
        phoneNumber={phoneNumber}
        setPhoneNumber={setPhoneNumber}
        callCode={callCode}
        setCallCode={setCallCode}
      />
      {errorMessages.phone && error('phone')}
      {errorMessages.phoneInvalid && error('phoneInvalid')}

      <View style={[styles.row, styles.marginTop]}>
        <View style={{ flex: 1, marginRight: 6 }}>
          <CustomText font={'smallBold'}>
            {txt('christmasFriends.createEditPost.postalCode')}
          </CustomText>
          <InputPrimary
            accessibilityLabel={txt('christmasFriends.createEditPost.postalCode')}
            accessibilityHint={txt('christmasFriends.createEditPost.postalCodeHint')}
            placeholder={txt('christmasFriends.createEditPost.postalCode')}
            header={txt('christmasFriends.createEditPost.postalCode')}
            value={postState.postalCode?.toString()}
            inputMode="numeric"
            onChangeText={handlePostalCodeChange}
            maxLength={4}
          />
          {errorMessages.postalCode && error('postalCode')}
          {errorMessages.postalCodeDontExist && error('postalCodeDontExist')}
        </View>
        <View style={{ flex: 1, marginLeft: 6 }}>
          <CustomText font={'smallBold'}>
            {txt('christmasFriends.createEditPost.city')}
          </CustomText>
          <InputPrimary
            accessibilityLabel={txt('christmasFriends.createEditPost.city')}
            accessibilityHint={txt('christmasFriends.createEditPost.cityHint')}
            placeholder={txt('christmasFriends.createEditPost.city')}
            header={txt('christmasFriends.createEditPost.city')}
            value={postState.city}
            onChangeText={(text) => {
              updatePostState({ city: text })
            }}
          />
          {errorMessages.city && error('city')}
        </View>
      </View>

      <CustomText style={styles.marginTop} font={'smallBold'}>
        {txt('christmasFriends.createEditPost.chooseAmount')}
      </CustomText>
      <View style={[styles.row, styles.marginTop]}>
        <View>
          <CustomText font={'smallBold'} style={styles.counterText}>
            {txt('christmasFriends.createEditPost.adults')}
          </CustomText>
          <NumberPicker
            accessibilityLabel={txt('christmasFriends.createEditPost.adults')}
            accessibilityHint={txt('christmasFriends.createEditPost.adultsHint')}
            value={postState.numAdults}
            onValueChange={handleNumAdultsChange}
          />
        </View>

        <View>
          <CustomText font={'smallBold'} style={styles.counterText}>
            {txt('christmasFriends.createEditPost.children')}
          </CustomText>
          <NumberPicker
            accessibilityLabel={txt('christmasFriends.createEditPost.children')}
            accessibilityHint={txt('christmasFriends.createEditPost.childrenHint')}
            value={postState.numChildren}
            onValueChange={(value) => updatePostState({ numChildren: value })}
          />
        </View>
      </View>

      {type === 'host' && (
        <>
          <CustomText style={styles.marginTop} font={'smallBold'}>
            {txt('christmasFriends.createEditPost.chooseSpaces')}
          </CustomText>
          <View style={[styles.row, styles.marginTop]}>
            <View>
              <CustomText font={'smallBold'} style={styles.counterText}>
                {txt('christmasFriends.createEditPost.adults')}
              </CustomText>
              <NumberPicker
                accessibilityLabel={txt('christmasFriends.createEditPost.adults')}
                accessibilityHint={txt('christmasFriends.createEditPost.adultsHint')}
                value={postState.adultSpaces}
                onValueChange={handleAdultSpacesChange}
              />
            </View>
            <View>
              <CustomText font={'smallBold'} style={styles.counterText}>
                {txt('christmasFriends.createEditPost.children')}
              </CustomText>
              <NumberPicker
                accessibilityLabel={txt('christmasFriends.createEditPost.children')}
                accessibilityHint={txt('christmasFriends.createEditPost.childrenHint')}
                value={postState.childSpaces}
                onValueChange={(value) =>
                  updatePostState({ childSpaces: value })
                }
              />
            </View>
          </View>
        </>
      )}

      {post && (
        <View style={styles.checkBoxContainer}>
          <CustomSwitch
            value={postState.status === 1}
            onValueChange={toggleHidePost}
            style={{ marginRight: 12 }}
          />
          <CustomText>
            {/*{txt(`ownPosts.${postState.status > 0 ? 'visible' : 'hidden'}`)}*/}
            {txt(
              `christmasFriends.createEditPost.${
                postState.status > 0 ? 'visible' : 'hidden'
              }`
            )}
          </CustomText>
        </View>
      )}

			{allowRkMatch && (
				<View style={{ marginBottom: 24 }}>
					<View style={styles.checkBoxContainer}>
						<CheckBox checked={!postState.rkMatch} onPress={toggleRkMatch} />
						<CustomText
							accessibilityLabel={txt('christmasFriends.createEditPost.matchMyself')}
							style={{ flex: 1, paddingRight: 12 }}
						>
							{txt('christmasFriends.createEditPost.matchMyself')}
							<TouchableOpacity
								accessibilityRole="button"
								accessibilityLabel={txt('christmasFriends.createEditPost.matchMyselfLabel')}
								onPress={() => setInfoMatchMyselfVisible(true)}
							>
								<NormalIcon name="info-circle" size={16} style={{ paddingLeft: 6, top: 2 }} />
							</TouchableOpacity>
						</CustomText>
					</View>
					<View style={styles.checkBoxContainer}>
						<CheckBox checked={postState.rkMatch} onPress={toggleRkMatch} />
						<CustomText
							accessibilityLabel={txt('christmasFriends.createEditPost.rkMatch')}
							accessibilityHint={txt('christmasFriends.createEditPost.rkMatchHint')}
							style={{ flex: 1 }}
						>
							{txt('christmasFriends.createEditPost.rkMatch')}
							<TouchableOpacity
								accessibilityRole="button"
								accessibilityLabel={txt('christmasFriends.createEditPost.rkMatchLabel')}
								onPress={() => setInfoMatchVisible(true)}
							>
								<NormalIcon name="info-circle" size={16} style={{ paddingLeft: 6, top: 2 }} />
							</TouchableOpacity>
						</CustomText>
					</View>
				</View>
			)}

      <View style={styles.checkBoxContainer}>
        <CheckBox checked={postState.rkContact} onPress={toggleRkContact} />
        <CustomText
          accessibilityLabel={txt('christmasFriends.createEditPost.rkContact')}
          accessibilityHint={txt('christmasFriends.createEditPost.rkContactHint')}
          style={{ flex: 1 }}
        >
          {txt('christmasFriends.createEditPost.rkContact')}
        </CustomText>
      </View>

      {errorMessages.rkContact && error('rkContact')}

      <View style={[styles.row, styles.marginTop]}>
        {onDelete && (
          <ButtonPrimary
            accesibilityLabeL={txt('christmasFriends.createEditPost.delete')}
            title={txt('christmasFriends.createEditPost.delete')}
            style={{ backgroundColor: Colors.errorBackground }}
            onPress={() => setShowDeleteDialog(true)}
          />
        )}
        <ButtonPrimary
          title={txt(
            `christmasFriends.createEditPost.${post ? 'saveChanges' : 'header'}`
          )}
          accesibilityLabeL={txt(
            `christmasFriends.createEditPost.${post ? 'saveChanges' : 'header'}`,
          )}
          onPress={prepareSubmit}
          loading={submitting}
          success={success}
          error={submitError}
        />
      </View>
      {keyboardSpacerShow()}
      <DeleteDialog />
      <NoImageDialog
        visible={noImageDialog}
        submit={submitPostWithoutImage}
        dismiss={handleEditAgain}
        edit={editing}
      />
      <CustomDialog visible={imageInfo} onDismiss={() => setImageInfo(false)}>
        <ContentContainer>
          <CustomText font="small">
            {txt('christmasFriends.createEditPost.imageInfo')}
          </CustomText>
        <ButtonPrimary
          onPress={() => setImageInfo(false)}
          style={{ backgroundColor: Colors.blueAzure, marginTop: 12 }}
          title={txt('global.ok')}
          titleStyle={{ paddingHorizontal: 12, fontSize: 14}}
        />
        </ContentContainer>
      </CustomDialog>
      <MatchDialog
        visible={infoMatchVisible}
        close={() => setInfoMatchVisible(false)}
        title={txt('christmasFriends.createEditPost.rkMatchInfoTitle')}
        description={txt('christmasFriends.createEditPost.rkMatchInfo')}
      />
      <MatchDialog
        visible={infoMatchMyselfVisible}
        close={() => setInfoMatchMyselfVisible(false)}
        title={txt('christmasFriends.createEditPost.matchMyselfInfoTitle')}
        description={txt('christmasFriends.createEditPost.matchMyselfInfo')}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  checkBoxContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginTop: 12,
  },
  container: {
    alignSelf: 'center',
    marginBottom: 24,
    maxWidth: Layout.isSmallDevice ? '100%' : 800,
    paddingHorizontal: 12,
    width: '100%',
  },
  counterText: {
    marginBottom: 6,
    marginTop: 12,
    textAlign: 'center',
  },
  errorText: {
    color: Colors.red,
    marginTop: 6,
  },
  headerText: {
    fontFamily: 'oswald-bold',
    fontSize: 30,
    lineHeight: 36,
    marginVertical: 12,
    textAlign: 'center',
  },
  imagePickerContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  marginTop: { marginTop: 12 },
  row: { flexDirection: 'row', justifyContent: 'space-evenly' },
})
