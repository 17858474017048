export const groupThemes = [
  {
    id: 0,
    primary: '#2a6592',
    // secondary: '#ffffff',
    background: '#EDF7F9FF',
  },
  {
    id: 1,
    primary: '#FEC71E',
    // secondary: '#FFF3D7',
    background: '#fffbee',
    buttonText: '#333333',
  },
  {
    id: 2,
    primary: '#EE7602',
    // secondary: '#FFF0E1',
    background: '#fff7ee',
  },
  {
    id: 3,
    primary: '#d72d39',
    // secondary: '#FEE3E4',
    background: '#FEF5F5',
  },
  {
    id: 4,
    primary: '#DD166B',
    // secondary: '#FEE3EE',
    background: '#fff3f8',
  },
  {
    id: 5,
    primary: '#5a1158',
    // secondary: '#F1E0FF',
    background: '#f8f5ff',
  },
  {
    id: 6,
    primary: '#008766',
    // secondary: '#DEF9F2',
    background: '#f1fff1',
  },
]