import React, { useEffect, useState } from 'react'
import { createStackNavigator } from '@react-navigation/stack'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'

import { txt } from '../locales/i18n'
import TabBarIcon from '../components/icons/TabBarIcon'
import BoblsUniverseScreen from '../screens/Universe/Bobls/BoblsUniverseScreen'
import BoblUniverseScreen from '../screens/Universe/Bobls/BoblUniverseScreen'
import EditBoblUniverseScreen from '../screens/Universe/Bobls/EditBoblUniverseScreen'
import CreateBoblUniverseScreen from '../screens/Universe/Bobls/CreateBoblUniverseScreen'
import IntroUniverseScreen from '../screens/Universe/IntroUniverse'
import ActivitiesUniverseScreen from '../screens/Universe/Activities/ActivitiesUniverseScreen'
import UnionsUniverseScreen from '../screens/Universe/Unions/UnionsUniverseScreen'
import UnionUniverseScreen from '../screens/Universe/Unions/UnionUniverseScreen'
import EditUnionUniverseScreen from '../screens/Universe/Unions/EditUnionUniverseScreen'
import ActivityUniverseScreen from '../screens/Universe/Activities/ActivityUniverseScreen'
import EditActivityUniverseScreen from '../screens/Universe/Activities/EditActivityUniverseScreen'
import HerlevOffersScreen from '../screens/Universe/Menu/HerlevOffersScreen'
import KrifaOffersScreen from '../screens/Universe/KrifaOffersScreen'
import StackUniverseMenuScreen from '../screens/Universe/Menu/StackUniverseMenuScreen'
import ProfileScreen from '../screens/Universe/Menu/UniverseProfileScreen'
import { useNavigation, useRoute } from '@react-navigation/core'
import InboxUniverseScreen from '../screens/Universe/Conversations/InboxUniverseScreen'
import ConversationUniverseScreen from '../screens/Universe/Conversations/ConversationUniverseScreen'
import ConversationInfoUniverseScreen from '../screens/Universe/Conversations/ConversationInfoUniverseScreen'
import { isUniverse, getUniverseConfig, extractUniverseFromRoute } from '../universe/universeController'
import { Platform } from 'react-native'
import { stackConfig } from './navigationConfig'
import SettingsUniverseScreen from '../screens/Universe/Settings/SettingsUniverseScreen'
import NotificationsUniverseScreen from '../screens/Universe/Settings/NotificationsUniverseScreen'
import { selectUnreadGroupMessages, selectUnreadMessages } from '../redux/selectors'
import { useSelector } from 'react-redux'
import GroupUniverseScreen from '../screens/Universe/Groups/GroupUniverseScreen'
import GroupsUniverseScreen from '../screens/Universe/Groups/GroupsUniverseScreen'
import { Modules } from '../constants/Modules'
import UniverseProfileViewScreen from '../screens/Universe/Menu/UniverseProfileViewScreen'
import ProfileViewScreen from '../screens/Profile/ProfileViewScreen'
import { EventFeedScreen } from '../screens/Events/EventFeedScreen'
import { EventScreen } from '../screens/Events/EventScreen'
import StoriesScreen from '../screens/Stories/StoriesScreen'
import StoryScreen from '../screens/Stories/StoryScreen'
import Layout from '../constants/Layout'

function IntroStack({ universe, universeObject }) {
	const Stack = createStackNavigator()
	return (
		<Stack.Navigator screenOptions={{ ...stackConfig({ universeConfig: universeObject }), headerMode: 'float' }}>
			<Stack.Screen
				name="IntroUniverse"
				component={IntroUniverseScreen}
				initialParams={{ universe }}
				options={{ title: universeObject?.title }}
			/>
		</Stack.Navigator>
	)
}

function UniverseBoblStack({ universe }) {
	const Stack = createStackNavigator()
	const initRouteName = universe === 'herlev' ? 'HerlevOffers' : 'Bobls'
	return (
		<Stack.Navigator
			initialRouteName={initRouteName}
			screenOptions={stackConfig({
				universeConfig: getUniverseConfig(universe),
			})}
		>
			<Stack.Screen
				name="Bobls"
				component={BoblsUniverseScreen}
				options={{ title: txt('home.title') }}
				initialParams={{ universe }}
			/>
			<Stack.Screen
				name="HerlevOffers"
				component={HerlevOffersScreen}
				options={{ title: txt('universe.herlev.offers') }}
				initialParams={{ universe }}
			/>
			<Stack.Screen
				name="Post"
				component={BoblUniverseScreen}
				options={{ title: '' }}
			/>
			<Stack.Screen
				name="EditPost"
				component={EditBoblUniverseScreen}
				options={{ title: txt('post.editTitle') }}
			/>
			<Stack.Screen
				name="Conversation"
				component={ConversationUniverseScreen}
				options={{ title: txt('conversation.title') }}
			/>
			<Stack.Screen
				name="ConversationInfo"
				component={ConversationInfoUniverseScreen}
				options={{ title: txt('conversation.info') }}
			/>
		</Stack.Navigator>
	)
}

function UniverseConversationStack({ universe }) {
	const Stack = createStackNavigator()
	return (
		<Stack.Navigator
			initialRouteName="Inbox"
			screenOptions={stackConfig({
				universeConfig: getUniverseConfig(universe),
			})}
		>
			<Stack.Screen
				name="Inbox"
				component={InboxUniverseScreen}
				options={{
					title: txt('conversations.title'),
					headerLeft: () => null,
				}}
				initialParams={{ universe }}
			/>
			<Stack.Screen
				name="Conversation"
				component={ConversationUniverseScreen}
				options={{ title: txt('conversation.title') }}
			/>
			<Stack.Screen
				name="ConversationInfo"
				component={ConversationInfoUniverseScreen}
				options={{ title: txt('conversation.info') }}
			/>
			<Stack.Screen
				name="Post"
				component={BoblUniverseScreen}
				options={{ title: '' }} />
			<Stack.Screen
				name="ProfileView"
				component={ProfileViewScreen}
				options={{ title: txt('profile.title') }}
			/>
		</Stack.Navigator>
	)
}

function UniverseCreatePostStack({ universe }) {
	const Stack = createStackNavigator()
	return (
		<Stack.Navigator
			screenOptions={stackConfig({
				universeConfig: getUniverseConfig(universe),
			})}
		>
			<Stack.Screen
				name="UniverseCreatePost"
				component={CreateBoblUniverseScreen}
				options={{ title: txt('createPost.title'), headerLeft: () => null }}
				initialParams={{ universe }}
			/>
		</Stack.Navigator>
	)
}

function UniverseActivityStack({ universe }) {
	const Stack = createStackNavigator()
	return (
		<Stack.Navigator
			initialRouteName="Activities"
			screenOptions={stackConfig({
				universeConfig: getUniverseConfig(universe),
			})}
		>
			<Stack.Screen
				name="Activities"
				component={ActivitiesUniverseScreen}
				options={{ title: txt('activities.title'), headerLeft: () => null }}
				initialParams={{ universe }}
			/>
			<Stack.Screen
				name="Activity"
				component={ActivityUniverseScreen}
				options={{ title: txt('activities.activity') }}
			/>
			<Stack.Screen
				name="EditActivity"
				component={EditActivityUniverseScreen}
				options={{ title: txt('activities.edit.title') }}
			/>
		</Stack.Navigator>
	)
}

function EventScreens() {
	const Stack = createStackNavigator()
	return (
		<>
			<Stack.Screen
				name="Events"
				component={EventFeedScreen}
				options={{ title: txt('events.title') }}
			/>
			<Stack.Screen
				name="Event"
				component={EventScreen}
				options={{ title: txt('event.title') }}
			/>
		</>
	)
}

function UniverseEventStack({ universe }) {
	const Stack = createStackNavigator()
	return (
		<Stack.Navigator
			initialRouteName="Events"
			screenOptions={stackConfig({
				universeConfig: getUniverseConfig(universe),
			})}
		>
			<Stack.Screen
				name="Events"
				component={EventFeedScreen}
				options={{ title: txt('events.title'), headerLeft: () => null }}
				initialParams={{ universe }}
			/>
			<Stack.Screen
				name="Event"
				component={EventScreen}
				options={{ title: txt('events.title') }}
			/>
		</Stack.Navigator>
	)
}

function UniverseUnionStack({ universe }) {
	const Stack = createStackNavigator()
	return (
		<Stack.Navigator
			initialRouteName="Unions"
			screenOptions={stackConfig({
				universeConfig: getUniverseConfig(universe),
			})}
		>
			<Stack.Screen
				name="Unions"
				component={UnionsUniverseScreen}
				options={{ title: txt('unions.title'), headerLeft: () => null }}
				initialParams={{ universe }}
			/>
			<Stack.Screen
				name="Union"
				component={UnionUniverseScreen}
				options={{ title: txt('unions.union.title') }}
			/>
			<Stack.Screen
				name="EditUnion"
				component={EditUnionUniverseScreen}
				options={{ title: txt('unions.edit.title') }}
			/>
		</Stack.Navigator>
	)
}

function UniverseOfferStack({ universe }) {
	const Stack = createStackNavigator()
	return (
		<Stack.Navigator
			initialRouteName="KrifaOffers"
			screenOptions={stackConfig({
				universeConfig: getUniverseConfig(universe),
			})}
		>
			<Stack.Screen
				name="KrifaOffers"
				component={KrifaOffersScreen}
				options={{ title: txt('universe.krifa.title'), headerLeft: () => null }}
				initialParams={{ universe }}
			/>
		</Stack.Navigator>
	)
}

function UniverseGroupsStack({ universe }) {
	const Stack = createStackNavigator()
	return (
		<Stack.Navigator
			initialRouteName="Groups"
			screenOptions={stackConfig({
				universeConfig: getUniverseConfig(universe),
			})}
		>
			<Stack.Screen
				name="Groups"
				component={GroupsUniverseScreen}
				options={{ title: txt('groups.title'), headerLeft: () => null }}
				initialParams={{ universe }}
			/>
			<Stack.Screen
				name="Group"
				component={GroupUniverseScreen}
				options={{ title: txt('groups.group') }}
			/>
		</Stack.Navigator>
	)
}

function UniverseMenuStack({ universe }) {
	const Stack = createStackNavigator()
	return (
		<Stack.Navigator
			initialRouteName="Menu"
			screenOptions={stackConfig({
				universeConfig: getUniverseConfig(universe),
			})}
		>
			<Stack.Screen
				name="Menu"
				component={StackUniverseMenuScreen}
				initialParams={{ universe }}
				options={{
					title: txt('mainTabNavigator.menuName'),
					headerLeft: () => null,
				}}
			/>
			<Stack.Screen
				name="HerlevOffers"
				component={HerlevOffersScreen}
				options={{ title: txt('universe.herlev.offers') }}
			/>
			<Stack.Screen
				name="Profile"
				component={ProfileScreen}
				options={{ title: txt('profile.title') }}
			/>
			<Stack.Screen
				name="ProfileView"
				component={UniverseProfileViewScreen}
				options={{ title: txt('profile.title') }}
			/>
			<Stack.Screen
				name="Settings"
				component={SettingsUniverseScreen}
				options={{ title: txt('settings.title') }}
			/>
			<Stack.Screen
				name="Notifications"
				component={NotificationsUniverseScreen}
				options={{ title: txt('settings.notifications') }}
			/>
			<Stack.Screen
				name="Groups"
				component={GroupsUniverseScreen}
				options={{ title: txt('groups.title') }}
			/>
			<Stack.Screen
				name="Group"
				component={GroupUniverseScreen}
				options={{ title: txt('groups.group') }}
			/>
			<Stack.Screen
				name="Stories"
				component={StoriesScreen}
				options={{ title: txt('stories.title') }}
			/>
			<Stack.Screen
				name="Story"
				component={StoryScreen}
				options={{ title: txt('story.title') }}
			/>

			{universe === 'herlev' && (
				<>
					<Stack.Screen
						name="Unions"
						component={UnionsUniverseScreen}
						options={{ title: txt('unions.title'), headerLeft: () => null }}
						initialParams={{ universe }}
					/>
					<Stack.Screen
						name="Union"
						component={UnionUniverseScreen}
						options={{ title: txt('unions.union.title') }}
					/>
					<Stack.Screen
						name="EditUnion"
						component={EditUnionUniverseScreen}
						options={{ title: txt('unions.edit.title') }}
					/>
				</>	
			)}

			{['aauucn', 'au', 'herlev', 'krifa'].includes(universe) && EventScreens()}
		</Stack.Navigator>
	)
}

function TabNavigator({ modules, universe, universeObject }) {
	const conversationsBadge = useSelector(selectUnreadMessages)
	const groupsBadge = useSelector(selectUnreadGroupMessages)
	const Tab = createBottomTabNavigator()

	return (
		<Tab.Navigator
			screenOptions={{
				headerShown: false,
				lazy: Platform.OS === 'web',
				tabBarStyle: { backgroundColor: universeObject?.tabBarColor },
				tabBarLabelStyle: { fontFamily: 'raleway-semibold' },
				tabBarActiveTintColor: universeObject?.tabBarActiveTextColor,
				tabBarInactiveTintColor: universeObject?.tabBarTextColor,
				tabBarItemStyle: {
					marginTop: 2,
					marginBottom: 2,
					borderRadius: 80,
				},
			}}
		>
			<Tab.Screen
				name="UniverseBoblStack"
				options={{
					tabBarLabel: txt('home.title'),
					tabBarIcon: ({ focused }) => (
						<TabBarIcon
							name="bobl"
							color={focused ? universeObject?.tabBarActiveTextColor : universeObject?.tabBarTextColor}
							stroke={focused ? 'fad' : 'fal'}
							boblbergIcon={true}
						/>
					),
				}}
			>
				{(props) => <UniverseBoblStack universe={universe} {...props} />}
			</Tab.Screen>
			<Tab.Screen
				name="UniverseConversationStack"
				options={{
					tabBarLabel: txt('conversations.title'),
					tabBarIcon: ({ focused }) => (
						<TabBarIcon
							name="comment-lines"
							badge={conversationsBadge}
							color={focused ? universeObject?.tabBarActiveTextColor : universeObject?.tabBarTextColor}
							stroke={focused ? 'fad' : 'fal'}
						/>
					),
				}}
			>
				{(props) => <UniverseConversationStack universe={universe} {...props} />}
			</Tab.Screen>
			<Tab.Screen
				name="UniverseCreatePostStack"
				options={{
					tabBarLabel: txt('createPost.title'),
					tabBarIcon: ({ focused }) => (
						<TabBarIcon
							name="plus-circle"
							color={universeObject?.tabBarActiveTextColor}
							stroke={focused ? 'fad' : 'fas'}
						/>
					),
				}}
			>
				{(props) => <UniverseCreatePostStack universe={universe} {...props} />}
			</Tab.Screen>
			{modules?.includes(Modules.ACTIVITIES) && (
				<Tab.Screen
					name="UniverseActivityStack"
					options={{
						tabBarLabel: txt('activities.title'),
						tabBarIcon: ({ focused }) => (
							<TabBarIcon
								name="calendar-day"
								color={focused ? universeObject?.tabBarActiveTextColor : universeObject?.tabBarTextColor}
								stroke={focused ? 'fad' : 'fal'}
							/>
						),
					}}
				>
					{(props) => <UniverseActivityStack universe={universe} {...props} />}
				</Tab.Screen>
			)}
			{universe === 'nytaarsvenner' && (
				<Tab.Screen
					name="UniverseEventStack"
					options={{
						tabBarLabel: txt('events.title'),
						tabBarIcon: ({ focused }) => (
							<TabBarIcon
								name="calendar-star"
								color={focused ? universeObject?.tabBarActiveTextColor : universeObject?.tabBarTextColor}
								stroke={focused ? 'fad' : 'fal'}
							/>
						),
					}}
				>
					{(props) => <UniverseEventStack universe={universe} {...props} />}
				</Tab.Screen>
			)}
			{modules?.includes(Modules.UNIONS) && universe !== 'herlev' && (
				<Tab.Screen
					name="UniverseUnionStack"
					options={{
						tabBarLabel: txt('unions.title'),
						tabBarIcon: ({ focused }) => (
							<TabBarIcon
								name="users-class"
								color={focused ? universeObject?.tabBarActiveTextColor : universeObject?.tabBarTextColor}
								stroke={focused ? 'fad' : 'fal'}
							/>
						),
					}}
				>
					{(props) => <UniverseUnionStack universe={universe} {...props} />}
				</Tab.Screen>
			)}
			{universe === 'krifa' && (
				<Tab.Screen
					name="UniverseOfferStack"
					options={{
						tabBarLabel: txt('universe.krifa.tabBarLabel'),
						tabBarIcon: ({ focused }) => (
							<TabBarIcon
								name="grip-vertical"
								color={focused ? universeObject?.tabBarActiveTextColor : universeObject?.tabBarTextColor}
								stroke={focused ? 'fad' : 'fal'}
							/>
						),
					}}
				>
					{(props) => <UniverseOfferStack universe={universe} {...props} />}
				</Tab.Screen>
			)}
			{['krifa', 'herlev'].includes(universe) && Layout.window.width >= 375 && (
				<Tab.Screen
					name="UniverseGroupsStack"
					options={{
						tabBarLabel: txt('groups.title'),
						tabBarIcon: ({ focused }) => (
							<TabBarIcon
								name="users-class"
								badge={groupsBadge}
								color={focused ? universeObject?.tabBarActiveTextColor : universeObject?.tabBarTextColor}
								stroke={focused ? 'fad' : 'fal'}
							/>
						),
					}}
				>
					{(props) => <UniverseGroupsStack universe={universe} {...props} />}
				</Tab.Screen>
			)}
			<Tab.Screen
				name="UniverseMenuStack"
				options={{
					tabBarLabel: txt('mainTabNavigator.menuName'),
					tabBarIcon: ({ focused }) => (
						<TabBarIcon
							name="bars"
							// Only show badge if users is member of krifa and screen width is less than 375
							badge={
								(modules?.includes(Modules.GROUPS) && !['krifa', 'herlev'].includes(universe)) ||
								(['krifa', 'herlev'].includes(universe) && Layout.window.width < 375)
									? groupsBadge
									: 0
							}
							color={focused ? universeObject?.tabBarActiveTextColor : universeObject?.tabBarTextColor}
							stroke={focused ? 'fad' : 'fal'}
						/>
					),
				}}
			>
				{(props) => <UniverseMenuStack universe={universe} {...props} />}
			</Tab.Screen>
		</Tab.Navigator>
	)
}

export default function UniverseStack() {
	const navigation = useNavigation()
	const route = useRoute()
	const [universeObject, setUniverseObject] = useState(null)
	const [universe, setUniverse] = useState(null)
	const universes = useSelector((state) => state.user.universes)

	const findUniverse = (obj) => {
		Object.keys(obj).forEach((key) => {
			if (key === 'universe') {
				setUniverse(obj[key].toLowerCase())
				return
			}
			if (typeof obj[key] === 'object') {
				return findUniverse(obj[key])
			}
		})
	}

	if (!universe) {
		findUniverse(route)
	}

	useEffect(() => {
		setUniverseObject(getUniverseConfig(universe))
		if (!isUniverse(universe)) navigation.replace('app')
	}, [universe])

	function memberOfUniverse() {
		return !!universes.find((u) => u.name === universe)
	}

	function getModules() {
		return universes.find((uni) => uni.name === universe)?.modules
	}

	return memberOfUniverse() ? (
		<TabNavigator universe={universe} universeObject={universeObject} modules={getModules()} />
	) : (
		<IntroStack universe={universe} universeObject={universeObject} />
	)
}
