import { apiDelete, apiGet, apiPost } from './ApiRequest'
import { updateUser } from "./User"

const stopLoadingFunc = (success) => {
  //if (__DEV__) console.log("No stopLoadingFunc passed, success: " + success);
}

// VideoSessionBook
export async function bookSession(video_session_id) {
  const body = { video_session_id }
  const response = await apiPost(body, 'videochat/book')
  return response.json()
}

// VideoSessionCancelBooking
export async function cancelBooking(id) {
  const body = { video_session_id: id }
  await apiPost(body, 'videochat/cancelBooking')
}

export async function createBooking(booking) {
  const response = await apiPost(booking, 'videochat/create')
  if (response.ok === false) return await response.text()
  return await response.json()
}

export async function deleteBooking(id) {
  await apiPost({ video_session_id: id }, 'videochat/delete')
}

export async function getAvailableSessions(start_date, end_date) {
  const body = { start_date, end_date }
  const response = await apiPost(body, 'videochat/getAvailable')
  if (response.ok === false) return []

  const { data: availableSessions } = await response.json()
  return availableSessions
}

export async function getAvailableDates() {
  const response = await apiGet('videochat/getAvailableDates')
  return response?.data ?? []
}

export async function getBookedSessions() {
  const response = await apiGet('videochat/getBooked')
  return response.data ?? []
}

export async function getOwnBooked() {
  const response = await apiGet('videochat/getOwnBooked')
  return response?.data ?? []
}

export async function getOwnNotBooked() {
  const response = await apiGet('videochat/getOwnNotBooked')
  return response?.data ?? []
}

export async function getAllSessions() {
  const response = await apiGet('videochat/getAllSessions')
  return response?.data ?? []
}

// VideoSessionVolunteerHasAccess
export async function volunteerHasAccess(organization_name, password, stopLoading = stopLoadingFunc) {
  if (organization_name == null || password == null) {
    if (__DEV__)
      console.log('No organization_name or password was passed to check if volunteer has access function, returning.')
    stopLoading(false)
    return
  }
  const data = {
    organization_name,
    password,
  }

  return apiPost(data, `videochat/volunteerHasAccess`)
    .then((response) => response.json())
    .then((responseJson) => {
      if (!responseJson.data) {
        stopLoading(false)
        return
      }

      stopLoading(true)
      return responseJson.data[0]
    })
    .catch((error) => {
      if (__DEV__) console.error(error)
      stopLoading(false)
    })
}

export async function getPreviousBookedSessions() {
  const previousBookedSessions = await apiGet('videochat/previous')
  return previousBookedSessions ?? []
}

export async function getPreviousBookers(sorting) {
	const sortingQueryParam = sorting ? `?sorting=${sorting}` : '';

	const res = await apiGet(`videochat/previousBookers${sortingQueryParam}`);
	if (!res.data) return;
	return res.data;
}

export async function getWaitlist() {
  let res = await apiGet('videochat/waitlist')
  if (!res || res.status === 403) return
  return res.data
}

export async function addToWaitlist() {
  let res = await apiPost({}, 'videochat/waitlist')
  return res?.ok
}

export async function removeFromWaitlist(userId) {
  let res = await apiDelete({ userId }, `videochat/waitlist/${userId}`)
  return res?.ok
}

export async function getWaitlistUser(userId) {
  let res = await apiGet(`videochat/waitlist/${userId}`)
  if (!res) return null
  return res.data
}

export async function waitlistAccess() {
  let res = await apiGet('videochat/waitlist/access')
  if (!res) return
  return res.hasAccess
}

export async function getSnakSammenProfile() {
  const res = await apiGet('videochat/profile')
  if (!res?.data) return null
  return res.data
}

export async function updateSnakSammenProfile(description, data) {
  // save profile description
  let res = await apiPost({ description }, 'videochat/profile')
  if (!res.ok) return false

  // save user info
  return await updateUser(data)
}

export async function getVolunteerProfile(userId) {
  const res = await apiGet(`videochat/volunteerProfile/${userId}`)
  if (!res?.data) return null
  return res.data
}

export async function getSessionCounters(userId) {
  let res = await apiGet(`videochat/sessionCounters/${userId}`)
  if (!res?.data) return null
  return res.data
}

export async function getSessionsLast30Days() {
  let res = await apiGet(`videochat/sessionsLast30Days/`)
  if (!res) return
  return res?.sessionCount
}